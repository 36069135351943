// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 40%;
		clear:left;
	}

	dd {
		width: 60%;
	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
	font-weight:bold;
}

.btn {
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	display: table;
	font-size: rem($base-font-size);
	line-height: rem($base-line-height);
	padding: rem(17px) rem(67px) rem(19px);
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;
	border-radius:rem(35px);
	white-space:nowrap;

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	transform: none;
	overflow-x:hidden;
	width:100%;

	@include breakpoint(large) {
		padding: {
			top:rem(162px);
		}
	}
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	width: 100%;
	position:fixed;
	z-index:100;

	.iexplore#navi-toggled:target & {
		position:absolute;

		@include breakpoint(large) {
			position:fixed;
		}
	}

	@include breakpoint(large) {
		height:0;
	}

	.branding {
		display: block;
		float: right;
		line-height:rem(83px);
		width:80%;

		img {
			vertical-align:middle;
		}

		@include breakpoint(large) {
			float: left;
			width:auto;
			margin-top:rem(-130px);
			line-height:auto;

			img {
				vertical-align:middle;
			}
		}

		@include breakpoint(xhuge) {
			margin-top:rem(-115px);
			transition:300ms margin ease-in-out;

			.iexplore & {
				transition:none;
			}

			&.fixed {
				margin-top:rem(-155px);
			}
		}
	}
}

// Under navi bar

.under-navi-bar {
	background: #fff; /* Old browsers */
	line-height:rem(48px);
	font-size:rem(16px);
	color:$darker;
	text-align:center;
	padding: {
		top:rem(83px);
	}
	.mobile-vers {

		@include breakpoint(large) {
			display:none;
		}
	}

	.desktop-vers {
		display:none;

		@include breakpoint(large) {
			display:block;
		}
	}

	@include breakpoint(tiny) {
		font-size:rem(24px);
	}

	@include breakpoint(large) {
		font-size:rem(18px);
	}
	@include breakpoint(giant) {
		font-size:rem(22px);
	}
	@include breakpoint(huge) {
		font-size:rem(24px);
	}

	@include breakpoint(large) {
		padding: {
			top:rem(3px);
		}
		line-height:rem(95px);
	}

	span.highlight {
		i {
			margin-right:rem(7px);
		}
		margin-right:rem(2px);
	}

	a {
		color:$darker;

		&:first-of-type {
			margin-left:rem(9px);
		}
	}

	span.fast-to-call {
		font-style:italic;
	}
}

// Main slider

.mainSlider {height:rem(300px);
				overflow: hidden;

				@include breakpoint(medium) {
					height:rem(696px);

					body:not(.index) & {
						height:rem(348px);
					}
				}
			

	#mainSlider {
		margin-bottom:0;
		overflow:hidden;
		li {

			&.slide {
				background-repeat:no-repeat;
				background-size:cover;
				height:rem(300px);
				float:left;
				width:100%;

				@include breakpoint(medium) {
					height:rem(696px);

					body:not(.index) & {
						height:rem(348px);
					}
				}
			}
			
			&.first.slide {
				background-image:image-url("slide-auto-fahrschule.jpg");
				background-position:center center;

				body:not(.index) & {
					background-position:top center;
				}
			}
			&.second.slide {
				background-image:image-url("slide-motorrad.jpg");
			}
			&.third.slide {
				background-image:image-url("slide-auto-bestanden.jpg");
				background-position:right rem(-100px) center;

				body:not(.index) & {
					background-position:right rem(-100px) top;
				}

				@include breakpoint(giant) {
					background-position:center center;

					body:not(.index) & {
						background-position:top center;
					}
				}
			}

			.nojs &:not(.first) {
				display:none;
			}
		}
	}
}

// Teaser
.teaser {
	margin: {
		top:rem(30px);
		bottom:rem(70px);
	}

	@include breakpoint(medium) {
		margin: {
			top:rem(-105px);
			bottom:rem(75px);
		}
	}
	.teaser-text-wrapper {
		text-align:center;
		background:$light;
		padding: {
			top:rem(66px);
		}
		box-shadow: 0px 0px 76px 0px rgba(34, 31, 31, 0.24); 
		height:rem(312px);
		position: relative;
		overflow:hidden;

		&::before {
			position: absolute;
			content:"\f00c";
			color:rgba(#4c4f51,.12);
			font-size:rem(144px);
			font-family:$icon-font;
			bottom:rem(16px);
			left:rem(-36px);
		}
	}
	span.heading {
		font-size:rem(18px);
		font-weight:300;
		text-transform:uppercase;
		color:$primary;
		display:block;

		@include breakpoint(medium) {
			font-size:rem(22px);
		}
	}

	span.highlight {
		font-weight:bold;
		font-size:rem(40px);
		line-height:rem(71px);
		display:block;
		margin-top:rem(6px);
		margin-bottom:rem(8px);

		@include breakpoint(medium) {
			font-size:rem(60px);
		}
	}

	a {
		color:$primary;
		font-style:italic;
		font-weight:300;
		font-size:rem(24px);
		line-height:rem(71px);
	}

	.teaser-text-wrapper-2 {
		
		text-align:left;
		padding: {
			top:rem(20px);
			left:rem(20px);
			bottom:rem(20px);
			right:rem(20px);
		}
		height:auto;
		margin-top:rem(30px);


		@include breakpoint(large) {
			
			display:flex;
			align-items:flex-start;
			padding: {
				left:rem(40px);
				top:rem(66px);
				bottom:0;
				right:rem(40px);
			}
			height:rem(312px);
		}

		@include breakpoint(giant) {
			margin-top:0;
			padding: {
				top:rem(20px);
				left:rem(20px);
				bottom:rem(20px);
				right:rem(20px);
			}
		}

		@include breakpoint(huge) {
			padding: {
				left:rem(81px);
				top:rem(66px);
				bottom:0;
				right:0;
			}
		}


		&::before {
			content:'\f017';
		}

		.column-1,
		.column-2,
		.column-3 {

			@include breakpoint(medium) {
				display:inline-block;
			}

			span {
				display:block;
				color:$primary;

				&.heading {
					text-transform:none;
					font-weight:bold;
					margin: {
						bottom:rem($base-gap);
					}
				}

				&:not(.heading) {
					font-size:rem(20px);
					font-weight:300;
				}

				&.oeffnungszeiten,
				&.theorie-unterricht {
					font-style:italic;

					& + span {
						margin: {
							top:rem(-6px);
						}
					}
				}

				&.theorie-unterricht {
					margin-top:rem($base-gap);
				}
			}
		}

		.column-1,
		.column-2 {
			margin-bottom:rem(20px);
			@include breakpoint(small) {
				width:50%;
				float:left;
			}

			@include breakpoint(large) {
				width:auto;
				float:none;
				margin-bottom:0;
				margin-right:rem(73px);
			}

			@include breakpoint(giant) {
				margin-right:rem(20px);
			}

			@include breakpoint(huge) {
				margin-right:rem(73px);
			}
		}
	}
}
// End of Teaser

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	body:not(.index) & {
		padding: {
			bottom:rem(100px);
		}
	}

	// First text section

	.first-section {
		padding: {
			bottom:rem(70px);
		}
		
		@include breakpoint(medium) {
			padding: {
				bottom:rem(30px);
			}

			body:not(.index) & {
				padding: {
					bottom:rem(60px);
				}
			}
		}

		p {
			font-size:rem(18px);
			line-height:rem(37px);
			text-align:center;
			font-weight:300;
			margin: {
				top:rem(22px);
			}

			@include breakpoint(small) {
				font-size:rem(24px);
			}
		}

		a.btn {
			margin:rem(50px) auto 0;

			@include breakpoint(small) {
				margin:rem(92px) auto 0;
			}
		}
	}

	ul {
		@extend .styled-list;
	}

	

}

.google-maps {
		
		
			height: rem(400px);
		margin-bottom: rem(100px);
	}


// Unser Team

span.name {
	font-weight:bold;
	display:block;
}

// Theorie plan

.pdf-btn {
	margin-bottom:rem(2 * $base-gap);

	@include breakpoint(large) {
		margin-bottom:0;
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;

	#footerSlider {
		overflow:hidden;
		margin-top: rem(70px);

		li {
			float:left;
			width:100%;
		}

		.nojs & {
			background:image-url("image-grid.jpg");
			background-size:cover;
			background-position:center center;
			height:rem(300px);
		}
		img {
			min-height:rem(300px);
		}

		.nojs & li {
			display:none;
		}
	}

	// Second section
	.second-section {
		margin-top:rem(50px);
		margin-bottom:rem(50px);

		@include breakpoint(large) {
			margin-top:rem(205px);
			margin-bottom:rem(167px);
		}

		div.decoration {
			width:100%;
			height:1px;
			background:$secondary;
			margin: {
				top:rem(28px);
			}

			@include breakpoint(small) {
				margin: {
					top:rem(41px);
				}
			}
		}

		p {
			font-weight:300;
		}

		a.btn {
			margin-top:rem(35px);
		}
	}

	// Fahrschulcard

	.fahrschulcard {
		background:image-url("fahrschulcard-bg.jpg");
		background-size:cover;
		background-repeat:no-repeat;
		height:rem(409px);
		text-align:center;
		padding: {
			top:rem(149px);
		}

		img {
			width:auto;
			margin: {
				bottom:rem(36px);
			}
		}

		p, a {
			font-size:rem(24px);
			line-height:rem($base-line-height);
			color:$secondary;
			font-weight:300;

			@include breakpoint(tiny) {
				font-size:rem(30px);
			}
		}
	}

	// Get the apps
	.get-app {
		text-align:center;
		background:$medium;
		color:$primary;
		font-weight:300;
		font-size:rem(18px);
		line-height:rem(67px);

		@include breakpoint(medium) {
			font-size:rem(22px);

			br {
				display:none;
			}
		}

		strong {
			font-weight:bold;
		}
		img {
			width:auto;
			vertical-align:middle;

			@include breakpoint(medium) {
				&:first-of-type {
					margin-left:rem(21px);
					margin-right:rem(7px);
				}
			}
		}
	}

	// Erste hilfe
	.erste-hilfe {
		height:rem(358px);
		
		position: relative;
		overflow:hidden;

		@include breakpoint(medium) {
			background-image:image-url("erste-hilfe-bg.jpg");
			background-repeat:no-repeat;
			background-position: right center;
		}

		.hilfe-info {
			background:#ffff00;
			position: absolute;
			
			width:100%;
			left:0;
			top:0;
			text-align:center;
			height:100%;
			padding: {
				top:rem(78px);
			}
			position: relative;

			@include breakpoint(medium) {
				width:50%;
				padding: {
					left:rem(100px);
				}
				max-width:rem(590px);
			}

			@include breakpoint(large) {
				padding: {
					left:rem(213px);
				}
			}

			&::after {
				position: absolute;
				height:rem(2 * 358px);
				width:rem(44px);
				border: {
					top: rem(358px) solid transparent;
					bottom:rem(358px) solid transparent;
					right:rem(22px) solid transparent;
					left:rem(22px) solid #ffff00;
				}
				right:rem(-44px);
				bottom:0;
				content:"";
			}

			img {
				width:auto;
				display:block;
				margin:0 auto rem(26px);

				@include breakpoint(medium) {
					padding: {
						left:rem(20px);
					}
				}
			}

			span {
				display:block;

			}

			span.heading {
				font-size:rem(37px);
				line-height:rem(30px);
				color:#0c3f94;
				font-weight:bold;
				margin: {
					bottom:rem(10px);
				}
			}

			a {
				font-size:rem(25px);
				line-height:rem(44px);
				font-weight:300;
				color:#0c3f94;
			}

			i {
				color:#0c3f94;
				font-size:rem(25px);
				line-height:rem(44px);
				margin-left:rem(6px);
			}

		}
		
	}

	// Locations
	.locations {
		
		background-image:image-url("locations-bg.jpg");
		background-repeat:no-repeat;
		background-size:cover;
		color:$light;
		text-align:center;
		padding: {
			top:rem(200px);
			bottom:rem(50px);
		}
		position: relative;

		@include breakpoint(large) {
			height:rem(788px);
			padding: {
				top:rem(402px);
				bottom:0;
			}
		}

		&::before {
			color:$light;
			content:"\f041";
			position:absolute;
			font-family:$icon-font;
			font-size:rem(102px);
			top:rem(85px);
			left:50%;
			transform:translate(-50%,0);

			@include breakpoint(large) {
				top:rem(264px);
			}
		}
		span.heading {
			font-size:rem(30px);
			line-height:rem(30px);
			font-weight:bold;

			@include breakpoint(medium) {
				font-size:rem(36px);
			}
		}

		div.address {
			font-size:rem(30px);
			line-height:rem(37px);
			font-weight:300;
			margin-bottom:rem(2 * $base-gap);

			@include breakpoint(large) {
				margin-bottom:0;
			}
		}
	}
}

// News feed in footer

.ewcms_news_feed {


	span {
		font-size: rem($h3-size);
		line-height:rem(30px);
		font-weight:bold;
		margin-top:rem(-7px);
		margin-bottom:rem(13px);
	}

	.ewcms_news_link {
		display: none;
	}
}

#navi-toggled:target .notificationArea {
	display: none !important;
}

#navi-toggled:target .privacyControlBadge {
	display: none !important;
}