/*=NAVIGATION MAIN */
.navi-main {

	@include breakpoint(large) {
		display:flex;
		justify-content:space-between;
	}

	@include breakpoint(xhuge) {
		display:block;
		text-align:right;
	}

	// links from first level only
	& > li {
		display:block;
		border-bottom:1px solid $light;
		// transition:300ms all ease-in-out;

		.iexplore & {
			transition:300ms color ease-in-out;
		}

		@include breakpoint(large) {
			display:inline-block;
			border:1px solid transparent;
		}

		@include breakpoint(xhuge) {
			margin-left:rem(30px);
		}

		&.unser-team {
			margin-left:0;
		}

		// links which should not be shown on desktop navi
		&.startseite,
		&.kontakt,
		&.datenschutzerklaerung,
		&.impressum {
			@include breakpoint(large) {
				display:none;
			}
		} // end of links which should not be shown on desktop navi

		// links with sub navi
		&.hasSub {
			position: relative;
			border-bottom:1px solid transparent;

			&:hover,
			&.active,
			&:active,
			&:focus {
				& > a {
					color:$secondary;
					border-bottom:1px solid $secondary;

					&::after {
						color:$secondary;
					}
				}
				border-bottom:1px solid transparent;

				.sub li {
					a {
						color:$light;
					}
					border-bottom:1px solid $light;

					&:hover,
					&:active,
					&.active,
					&:focus {
						a {
							color:$secondary;
						}
						
						border-bottom:1px solid $secondary;
					}
				}
			}

			& > a {
				border-bottom:1px solid $light;
				// transition:300ms all ease-in-out;

				.iexplore & {
					transition:300ms color ease-in-out;
				}
				&::after {
					content:"\f107";
					font-family: $icon-font;
					font-size:rem(21px);
					color:$light;
					padding: {
						left:rem(6px);
					}
					transition:300ms color ease-in-out;

					@include breakpoint(large) {
						color:$primary;
					}
				}
			}
		}

		&:hover {
			border-bottom:1px solid $secondary;
			a {
				color:$secondary;
			}

			.sub {
				opacity:1;
				visibility:visible;
			}
		}

		&.active {
			border-bottom:1px solid $secondary;
			a {
				color:$secondary;
			}
		}

		& > a {
			font-size:rem(21px);
			font-weight: 300;
			text-transform: uppercase;
			color:$light;
			display:block;
			text-decoration:none;
			line-height:rem(40px);
			transition:300ms color ease-in-out;

			@include breakpoint(large) {
				color:$primary;
				line-height:auto;
			}
		}

		&.standorte,
		&.fuehrerscheinklassen,
		&.unser-service {
			margin-right:rem(3px);
			& > a {
				font-weight:bold;
			}
		}
	} // end of links from the first level

	// sub navi
	.sub {
		text-align:left;

		@include breakpoint(large) {
			visibility: hidden;
			opacity:0;
			position: absolute;
			transition:300ms opacity ease-in-out;
			padding: {
				top:rem($base-gap);
				left:rem($base-gap);
				right:rem($base-gap);
				bottom:rem(30px);
			}
			top:100%;
			background:$primary;
			z-index:-1;
		}
		li {	
			display:block;
			border-bottom:0 none !important;
			// transition:300ms all ease-in-out;

			.iexplore & {
				transition:none;
			}

			&:hover,
			&:focus,
			&.active,
			&:active {

				a {
					color:$light !important;
					background:rgba($light,.3);
					padding: {
						left:rem(10px);
						right:rem(10px);
					}
				}
			}
		}

		a {
			font-size:rem(21px);
			font-weight: 300;
			line-height:rem(40px);
			white-space:nowrap;
			display:block;
			text-decoration:none;
			text-transform:uppercase;
			font-weight:300;
			color:$light;
			transition:300ms background ease-in-out, 300ms padding ease-in-out;
			padding: {
				left:rem(20px);
			}

			@include breakpoint(large) {
				padding-left:0;
			}
		}
	}
}

// Off-canvas navigation
.page-navi {
	background: rgba(0,0,0,.3);
	min-height: 100%;
	transform: translateX(-100%);
	box-shadow:0 0 76px 1px rgba(#606561,.2);
	left: 0;
	position: absolute;
	top: 0;
	width: 80%;
	z-index: 100;
	padding: {
		top:rem(30px);
	}

	@include breakpoint(large) {
		background: $light;
		transform: translateX(0);
		transition: none;
		min-height: inherit;
		position: fixed;
		width: 100%;
		height:rem(162px);
		padding: {
			top:rem(120px);
		}
		transition:300ms height ease-in-out, 300ms padding ease-in-out;

		.iexplore & {
			transition:none;
		}

		&.fixed {
			height:rem(100px);
			padding: {
				top:rem(30px);
			}
		}
	}

	@include breakpoint(xhuge) {
		padding: {
			top:rem(75px);
		}
	}
}

.toggle-navi, .close-navi {
	color: $dark;
	font-size: rem(32px);
	text-decoration: none;

	&:before {
		@extend .fa-default;
		@extend .fa-bars;
		transition: content 300ms;
		line-height: 2.6em;
	}

	&:hover {
		&:before {
			color: $primary;
		}
	}

	@include breakpoint(large) {
		display: none;
	}
}

.close-navi {
	display: none;
}

.navi-buttons {
	float: left;
}

#navi-toggled:target {
	.page-navi {
		transform: none;
	}

	.page-wrap {
		transform: translateX(80%);
		position: fixed;
	}

	.close-navi {
		display: block;

		&:before {
			@extend .fa-close;
		}
	}

	.toggle-navi {
		display: none;
	}

	@include breakpoint(large) {
		.close-navi {
			display: none;
		}

		.page-wrap {
			transform: none;
			position: relative;
		}
	}
}

// Footer navi

.footer-navi {
	background:#0f4971;
	display:none;

	@include breakpoint(large) {
		display:block;
	}
}

.navi-add {
	display:flex;
	justify-content:space-between;

	li {
		display:inline-block;

		&.standorte,
		&.fuehrerscheinklassen,
		&.unser-service {
			& > a {
				font-weight:bold;
			}
		}
		&.hasSub {
			position: relative;

			&:hover {
				.sub {
					visibility:visible;
					opacity: 1;
				}
			}

			& > a {
				&::after {
					content:"\f107";
					font-family: $icon-font;
					font-size:rem(14px);
					color:$light;
					padding: {
						left:rem(6px);
					}

					@include breakpoint(giant) {
						font-size:rem(18px);
					}

					@include breakpoint(huge) {
						font-size:rem(21px);
					}
				}
			}
			.sub {
				visibility:hidden;
				opacity:0;
				position: absolute;
				top:rem(-100px);
				transition:300ms opacity ease-in-out;
				left:rem(-20px);

				@include breakpoint(huge) {
					top:rem(-70px);
				}

				li {
					display:block;
					border-bottom:1px solid $light;
					transition:300ms background ease-in-out;

					.iexplore & {
						transition:none;
					}

					&:hover {

						a {
							background:rgba($light,.3);
						}
						
					}

					&.active,
					&:hover,
					&:active,
					&:focus {
						background:rgba($light,.3);
					}
				}

				a {
					color:$light;
					line-height:rem(36px);
					white-space:nowrap;
					display:block;
					transition:300ms padding ease-in-out;
					padding-left:rem(10px);
					padding-right:rem(10px);
				}
			}


		}

		a {
			color:$light;
			line-height:rem(100px);
			display:block;
			text-decoration:none;
			font-size:rem(14px);
			text-transform:uppercase;
			font-weight:300;
			transition:300ms color ease-in-out;

			@include breakpoint(giant) {
				font-size:rem(18px);
			}

			@include breakpoint(huge) {
				font-size:rem(21px);
				line-height:rem(185px);
			}
		}
	}

	& > li {
		&:hover,
		&.active,
		&:active,
		&:focus {
			& > a {
				color:$secondary;

				&::after {
					color:$secondary;
				}

				i {
					color:$secondary;
				}
			}
		}
	}
}